// Imports
import {
  make,
} from 'vuex-pathify'


const state = {
  items: {
    operating: {
      title: 'Indicados Rodando',
      items: [],
      empty: 'Nenhuma indicação em operação ainda.'
    },
    waiting: {
      title: 'Indicados na lista de espera',
      items: [],
      empty: 'Nenhuma indicação na lista de espera.'
    },
    // done: {
    //   title: 'Completos',
    //   items: [],
    // },
  }
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
