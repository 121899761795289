// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: {
          base: '#77F8E2',
          lighten1: '#A0F8EC',
          lighten2: '#BBFBF5',
          lighten3: '#D7FEFB',
          lighten4: '#EBFFFE',
        },
        accent: '#51F6CF',
        secondary: {
          darken4: '#333447',
          darken3: '#3B3B68',
          darken2: '#423F8D',
          darken1: '#4845B0',
          base: '#5050CE',
          lighten1: '#70ECFF',
          lighten2: '#ADF4FF',
          lighten3: '#D6FAFF',
          lighten4: '#EBFDFF',
        },

        error: '#F56A47',
        warning: '#FFE814',
        info: '#70ECFF',
        success: '#51F6CF',
        background: '#181825',
        black: '#070711',

        grey: {
          darken4: '#181825',
          darken3: '#48485B',
          base: '#868698',
          lighten3: '#D8D8DF',
          lighten4: '#F4F4F6',
        },
      },
    },
  },
})
