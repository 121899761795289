// Imports
import {
  make,
} from 'vuex-pathify'

const state = {
  cpf: null,
  status: null,
  statusChange: null,
  express: null,
  profile: {
    partner: null,
    fullname: null,
    photo: null,
    birthdate: null,
    email: null,
    phone: null,
    gender: null,
    signedupAt: null,
    rg: {
      id: null,
      issuer: null
    },
    cnh: null,
    club_id: null,
    job: {
      products: null,
      special_projects: null,
      apps: null,
      service_model: null,
      fulltime: null,
      journey: null,
      region: null,
      rating: null,
      experience: null,
    },
    gps: null,
    push: null,
    app_version: null,
    os: null,
    buzzer: {
      id: null,
      code: null,
      number: null,
      reviewedAt: null,
      reviewInterval: null
    },
    address: {
      state: null,
      city: null,
      zip: null,
      neighborhood: null,
      street: null,
      number: null,
      compl: null,
      checkedAddress: false
    },
    vehicle: {
      plate: null,
      chassis: null,
      brand: null,
      model: null,
      year: null,
      parking: null,
      color: null,
      renavam: null,
      fuel: null,
      isOwner: true,
      ownerName: null,
      ownerEmail: null,
      ownerRg: null,
      ownerOrg_exp: null,
      ownerCpf: null,
      ownerRelative: null,
    },
    payment: {
      bankCode: null,
      bank: null,
      branch: null,
      account: null,
      digit: null,
      type: null,
      compl: null
    },
  },
  auth: {
    token: null,
    timestamp: null,
    status: false,
    code: null,
    valid: null,
    sms: false
  },
  bankList: []
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
