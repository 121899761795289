import WebFontLoader from 'webfontloader'

// async load fonts
WebFontLoader.load({
  google: {
    families: [
      'Roboto:100,300,400,500,700,900',
      'Roboto Mono:400,500,600,700',
    ],
  },
})
