// Utilities
import {
  make,
} from 'vuex-pathify'


const state = {
  onboarding: {
    toggle: false,
    slide: 0,
  }
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
