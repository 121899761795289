// Imports
import Vue from 'vue'
import Vuex from 'vuex'
import pathify from 'vuex-pathify'
import VuexPersist from 'vuex-persist';
import localForage from 'localforage';

import * as modules from './modules'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: localForage, 
  asyncStorage: true,
})

const store = new Vuex.Store({
  plugins: [pathify.plugin, vuexLocalStorage.plugin],
  modules,
})

export default store
