// Imports
import {
  make,
} from 'vuex-pathify'


const state = {
  data: {
    payments: [],
    hours: {
      cycles: {},
      updatedAt: null
    },
  },
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
