<template>
  <p class="title font-weight-light text-center">
    <slot />
  </p>
</template>

<script>
  export default {
    name: 'BaseHeading',
  }
</script>
