/* eslint-disable no-console */

import { Workbox } from "workbox-window";
import device from 'mobile-device-detect';

let wb;

if (!device.isAndroid&&process.env.NODE_ENV !== 'development' && "serviceWorker" in navigator) {
  console.log('service worker...');
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

  wb.addEventListener("controlling", () => {
    window.location.reload();
  });

  wb.addEventListener("updated", (registration) => {
    console.log('New content is available; please refresh.');
    document.dispatchEvent(
      new CustomEvent('swUpdated', { detail: registration })
    );
  }),

  wb.register();
} else {
  console.log(process.env.NODE_ENV, 'service worker –');
  wb = null;
}

export default wb;
