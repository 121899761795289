// Imports
import Vue from 'vue'
import Router from 'vue-router'
import vuetify from '@/plugins/vuetify'
import kebabCase from 'lodash/kebabCase'
import store from '@/store/index';
import VueGtag from "vue-gtag";

Vue.use(Router)

function layout (path, name, children) {
  const folder = kebabCase(name);

  return {
    path,
    component: () => import(`@/layouts/${folder}/Index`),
    children,
  }
}

function redirect (redirect) {
  return { path: '*', redirect }
}

function route (path, folder, name, file='Index', meta=null, children=null, redirect=null) {
  return {
    path,
    name: !!children ? null : name,
    component: () => import(`@/views/${folder}/${file}.vue`),
    children,
    redirect,
    meta
  }
}

function subroute (path, folder, name, file='Index', meta) {
  return {
    path,
    name,
    component: () => import(`@/views/${folder}/${file}.vue`),
    meta
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return vuetify.framework.goTo(scrollTo)
  },
  routes: [
    route('/maintenance', 'login', 'Manutenção', 'Maintenance'),
    route('/file', 'login', 'file', 'ImageViewer'),

    layout('/main', 'Home', [
      route('', 'home', 'main', 'Index', { title: 'Mobees' }, [
        subroute('home', 'home', 'home', 'Home', { title: 'Mobees' }),
        subroute('payments', 'home', 'payments', 'Payments', { title: 'Extrato' }),
        subroute('bonus', 'home', 'bonus', 'Bonus', { title: 'Bônus' }),
        // subroute('help', 'help', 'Ajuda', 'Help'),
        // subroute('club', 'help', 'Clube', 'Benefits'),
        // subroute('profile', 'home', 'Perfil', 'Profile')
      ], { name: 'home' }),
    ]),
    layout('/signup', 'Home', [
      route(':step?', 'signup', 'signup', 'Index', { title: 'Processo Seletivo' }),
    ]),
    layout('/', 'Login', [
      route('', 'login', 'login', 'Index', { title: 'Login' }),
      route('tos', 'login', 'Termos de Uso'),
      route('privacy', 'login', 'Política de Privacidade'),
      route('esqueci-senha/:cpf?/:resetToken?', 'login', 'Recuperação de Senha'),
      route('cancel/:cpf?/:token?', 'login', 'Cancelar Conta'),
      route('termo-assinado', 'login', 'Termo Assinado'),
    ]),
    // redirect('/'),
  ],
})

router.beforeEach(async (to, from, next) => {
  await store.restored;
  next()
});


const toggle = process.env.NODE_ENV === 'production';

Vue.use(VueGtag, {
  enabled: toggle,
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
  },
  pageTrackerTemplate (route) {
    console.log(route.meta.title)
    return {
      page: route.path,
      title: route.meta.title + ' | Parceiros',
      location: window.location.href
    }
  },
  // debug: {
  //   // enabled: !toggle,
  //   sendHitTask: toggle,
  // },
}, router);

export default router
