import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import VueOffline from 'vue-offline'
import visibility from 'vue-visibility-change';

// Global assets
import IconBase from '@/components/IconBase'
import BrandSymbol from '@/components/icons/BrandSymbol'
import BrandLogo from '@/components/icons/BrandLogo'

import { defineCustomElements } from '@ionic/pwa-elements/loader';
// import { Plugins } from '@capacitor/core'

import VueGoogleCharts from 'vue-google-charts'


// PWA imports
import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;

// const { SplashScreen } = Plugins
import { SplashScreen } from '@capacitor/splash-screen';
Vue.config.productionTip = false

Vue.component('IconBase', IconBase);
Vue.component('BrandSymbol', BrandSymbol);
Vue.component('BrandLogo', BrandLogo);

Vue.use(visibility);

Vue.use(Croppa, { componentName: 'cropper' });

import moment from './plugins/moment';
Vue.use(moment);

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import VueNumber from 'vue-number-animation'
Vue.use(VueNumber)

Vue.use(VueOffline);

Vue.use(VueGoogleCharts);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted () {
    SplashScreen.hide();
    defineCustomElements(window);
  }
}).$mount('#app')

