<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewBox"
    :aria-labelledby="name"
    role="presentation"
    :preserveAspectRatio="aspectRatio"
    v-bind="$attrs"
    v-on="$listeners"
    :class="[colorClass]"
    @load="onLoad"
  >
    <title
      :id="name"
    >{{ name }} icon</title>
    <g :fill="!!colorClass ? 'currentColor' : color">
      <slot />
    </g>
    <defs>
      <slot name="defs" />
    </defs>
  </svg>
</template>

<style scoped>
  svg {
    display: block;
    width: 100%;
  }
  svg:focus {
    outline: none !important;
  }
</style>

<script>
  export default {
    inheritAttrs: false,
    props: {
      name: {
        type: String,
        default: 'icon'
      },
      width: {
        type: [Number, String],
        default: 18
      },
      height: {
        type: [Number, String],
        default: 18
      },
      color: {
        type: String,
        default: 'white'
      },
      aspectRatio: {
        type: String,
        default: ''
      }
    },
    computed: {
      colorClass () {
        return this.color.indexOf('#')<0 ? this.color + '--text' : null;
      },
      viewBox: function () {
        return "0 0 "+this.width+" "+this.height
      }
    },
    methods: {
      onLoad () {
        console.log('svg loaded');
        this.$emit('load');
      }
    }
  }
</script>