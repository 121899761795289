// Utilities
import {
  make,
} from 'vuex-pathify'


const state = {
  drawer: null,

  goal: {
    progress: 0,
    period: {
      start: null,
      end: null,
    },
    hours: {
      completed: 0,
      total: 80,
    },
    payment: {
      fixed: 0,
      variable: 0,
      variable_min: 0,
      variable_max: 0,
    },
    updatedAt: 0,
    loading: true
  },

  buzzer: {
    toggle: false,
    status: 'ON',
    mode: 'OPE',
    cache: false,
    cached: 0,
    timestamp: {
      status: '',
      impression: ''
    },
    loading: true,
    updatedAt: 0,
    display: {
      title: '',
      text: '',
    },
  },

  standbyConfirmation: {
    toggle: false,
    headline: 'Desligar Buzzer?',
    text: 'As horas com o Buzzer desligado NÃO contam para as Horas no ar.<br/><br/>Deseja desligar mesmo assim?',
    buttons: {
      ok: {
        text: 'Sim',
      },
      cancel: {
        text: 'Não',
      }
    }
  },

  views: {
    referrals: {
      toggle: false,
      title: 'Indicados',
      fullscreen: true,
      confirm: false,
      loading: false,
      updatedAt: 0,
    },
    payments: {
      title: 'Extrato',
      fullscreen: true,
      loading: false,
      updatedAt: 0,
    },
    bonus: {
      title: 'Prêmios',
      fullscreen: true,
      loading: false,
      updatedAt: 0,
    },
    profile: {
      title: 'Perfil',
      fullscreen: true,
      loading: false,
      pending: false,
      updatedAt: 0,
    },
    help: {
      title: 'Ajuda',
      context: null,
      section: null,
      item: null,
      tickets: null,
      ticket: null,
      advertising: {
        toggle: false,
      },
      calendar: null,
      loading: false,
      vehicles: [],
      updatedAt: 0,
      fullscreen: true,
      errors: [],
      version: null
    },
    tos: {
      title: 'Termos e Condições',
      toggle: false,
      accepted: false,
    },
    privacy: {
      title: 'Política de Privacidade',
      toggle: false,
    },
    forgotPassword: {
      title: 'Esqueci a senha',
      toggle: false,
      feedback: {
        toggle: false,
        timeout: -1,
        text: 'Informe o código abaixo:',
        button: true
      } 
    },
    notifications: {
      items: {},
      loading: false,
      updatedAt: 0,
    },
    onboarding: {
      toggle: false,
      seen: false,
      story: 0,
    },
  },

  version: null,

  toast: {
    toggle: false,
    text: '',
    timeout: -1,
    button: false,
    color: '',
  },
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
