<template>
  <v-app-bar app>
    <v-btn
      icon
    >
      <v-icon
        v-text="mdiMenu"
      />
    </v-btn>

    <v-spacer />

    <v-btn
      :to="{ name: 'Profile' }"
      exact
      icon
    >
      <v-icon v-text="mdiAccountCircle" />
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Utilities
  import {
    sync,
  } from 'vuex-pathify'

  // Icons
  import {
    mdiClose,
    mdiMenu,
    mdiAccountCircle,
  } from '@mdi/js'

  export default {
    name: 'TopBar',

    data: () => ({
      mdiClose,
      mdiMenu,
      mdiAccountCircle,
    }),

    computed: {
      // drawer: sync('pages/drawer'),
    },
  }
</script>
