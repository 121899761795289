// Imports
import {
  make,
} from 'vuex-pathify'


const state = {
  data: {
    items: {},
    selected: null,
    updatedAt: null
  },
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
