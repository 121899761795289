<template>
  <v-scroll-y-reverse-transition mode="out-in">
    <router-view
      :active="active"
      :installed="installed"
      :modal="modal"
      :dev="dev"
    />
  </v-scroll-y-reverse-transition>
</template>

<style>
  /* :root {
    --city-500 : rgba(90, 129, 237, 1);
    --city-50: rgba(247, 249, 252, 1);
    --city-100: rgba(220, 228, 249, 1);
    --city-200: rgba(174, 193, 244, 1);
    --city-300: rgba(126, 157, 241, 1);
    --city-400: rgba(105, 141, 242, 1);
    --city-600: rgba(80, 114, 226, 1);
    --city-700: rgba(75, 102, 210, 1);
    --city-800: rgba(69, 86, 176, 1);
    --city-900: rgba(61, 75, 143, 1);
    --city-a100: rgba(235, 240, 255, 1);
    --city-a200: rgba(145, 174, 253, 1);
    --city-a400: rgba(71, 108, 255, 1);
    --city-a700: rgba(54, 86, 211, 1);
    --creative-500 : rgba(237, 69, 103, 1);
    --creative-50: rgba(255, 222, 214, 1);
    --creative-100: rgba(255, 193, 184, 1);
    --creative-200: rgba(255, 143, 147, 1);
    --creative-300: rgba(255, 112, 126, 1);
    --creative-400: rgba(248, 89, 115, 1);
    --creative-600: rgba(191, 54, 109, 1);
    --creative-700: rgba(151, 53, 112, 1);
    --creative-800: rgba(125, 59, 114, 1);
    --creative-900: rgba(108, 55, 108, 1);
    --creative-a100: rgba(255, 220, 214, 1);
    --creative-a200: rgba(255, 153, 153, 1);
    --creative-a400: rgba(237, 90, 119, 1);
    --creative-a700: rgba(175, 49, 116, 1);
    --driver-green-a200: rgba(171, 237, 228, 1);
    --driver-green-500: #68DECE;
    --driver-green-400: rgba(69, 217, 207, 1);
    --driver-green-300: rgba(116, 231, 219, 1);
    --driver-green-200: rgba(168, 240, 235, 1);
    --driver-green-100: rgba(211, 248, 247, 1);
    --driver-green-50: rgba(238, 251, 252, 1);
    --driver-green-600: rgba(59, 182, 186, 1);
    --driver-green-700: rgba(53, 138, 151, 1);
    --driver-green-800: rgba(47, 102, 116, 1);
    --driver-green-900: rgba(39, 76, 83, 1);
    --mobees-black: rgba(38, 39, 44, 1);
    --mobees-black--accent: rgba(24, 24, 37, 1);
    --mobees-black--ligthen-1: rgba(72, 72, 91, 1);
    --mobees-black--ligthen-2: rgba(134, 134, 152, 1);
    --mobees-black--ligthen-3: rgba(216, 216, 223, 1);
    --driver-green-a400: rgba(104, 222, 206, 1);
    --driver-green-a100: rgba(211, 248, 243, 1);
    --driver-green-a700: rgba(31, 120, 142, 1);
    --mobees-bee: rgba(255, 237, 71, 1);
    --bee-yellow-200: #FEF4D7;
    --bee-yellow-400: #FCDA73;
    --error: #FF7C5C;
    --white-hover-overlay: rgba(255,255,255,.08);
    --driver-bg: radial-gradient(94.86% 53.36% at 0% 0%, rgba(69, 217, 207, 0.1) 0%, rgba(75, 102, 210, 0.05) 100%), var(--mobees-black);
    --v-grey-darken3: rgba(247,249,252,.16);
    --v-grey-darken3: rgba(247,249,252,.24);
  } */

  *:not(input):not(textarea) {
    user-select: none !important;
    -webkit-user-select: none !important; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none !important; /* Disable Android and iOS callouts*/
  }
  body input, body textarea {
    user-select: text !important;
    -webkit-touch-callout: text !important;
    -webkit-user-select: text !important;
  }
  ::-webkit-scrollbar {
    opacity: 0;
    width: 0;
    display: none;
    background-color: transparent;
  }

  html, body, #app.theme--dark.v-application {
    background: var(--v-background-base) !important;
    width: 100%;
    min-height: 100%;
    /* min-height: calc(100% + env(safe-area-inset-top));
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);  */
    /* height: calc(var(--vh, 1vh) * 100); */
  }
  #app .v-sheet, .v-input > .v-input__control > .v-input__slot {
    background-color: var(--v-background-base);
    /* margin-top: env(safe-area-inset-top) !important; */
  }
  #app .v-card.transparent, .v-input.transparent > .v-input__control > .v-input__slot {
    background-color: transparent !important;
    /* margin-top: env(safe-area-inset-top) !important; */
  }
  #app .v-overlay__scrim {
    background-color: var(--v-background-base) !important;
  }

  #app .text-caption {
    font-family: "Roboto Mono", sans-serif !important;
    font-size: .6975rem !important;
  }
  #app .text-tech {
    font-family: "Roboto Mono", sans-serif !important;
  }

  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title, .v-toolbar__extension > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 8px;
  }
  #app .container {
    overflow: hidden;
  }
  i {
    font-style: normal;
  }

  #app .v-dialog--fullscreen .sheet {
    height: 100%;
    border-radius: 0;
    background: var(--v-background-base);
    padding-top: env(safe-area-inset-top) !important;
    /* background: rgba(42,42,42,.6);
    backdrop-filter: blur(5px) !important; */
  }
  #app .sheet.fill-height {
    min-height: 100%;
    height: auto;
    background: var(--v-background-base);
  }
  #app .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 90%;
  }
  #app .v-dialog.v-dialog--fullscreen {
    max-height: 100%;
    top: auto;
  }

  #app .app-bar .title, #app .page-header .title {
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
    text-transform: uppercase !important;
    letter-spacing: .1rem !important;
  }

  #app .sheet .content {
    max-height: calc(100% - 56px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  #app .empty-icon {
    max-width: 40%;
  }

  #app .card-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    opacity: .24;
    pointer-events: none;
  }

  #app .theme--dark.v-btn:focus::before {
    opacity: 0 !important;
  }

  .scrollable {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .scrollable.x {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .smooth-scroll {
    scroll-behavior: smooth;
  }
  .prevent-scroll {
    overflow-y: hidden !important;
  }
  .snap {
    overflow-y: scroll !important;
    scroll-snap-type: y mandatory;
    /* scroll-padding: 8px;
    scroll-margin: 8px; */
  }
  .snap.x {
    overflow-x: scroll !important;
    scroll-snap-type: x mandatory;
    /* scroll-padding: 8px;
    scroll-margin: 8px; */
  }
  .snap.both {
    overflow: scroll !important;
    scroll-snap-type: both mandatory;
    /* scroll-padding: 8px;
    scroll-margin: 8px; */
  }
  .snap-target {
    scroll-snap-align: start;
  }

  .text-untruncate {
    overflow: initial !important;
    text-overflow: initial !important;
    white-space: initial !important;
  }

  .animate.pulse {
    animation: pulse 1.5s infinite;
  }

  @keyframes pulse {
    0% {
      opacity: .6;
    }
    50% {
      opacity: .4;
    }
    100% {
      opacity: .6;
    }
  }

  .theme--dark input:-webkit-autofill,
  .theme--dark input:-webkit-autofill:hover, 
  .theme--dark input:-webkit-autofill:focus,
  .theme--dark textarea:-webkit-autofill,
  .theme--dark textarea:-webkit-autofill:hover,
  .theme--dark textarea:-webkit-autofill:focus,
  .theme--dark select:-webkit-autofill,
  .theme--dark select:-webkit-autofill:hover,
  .theme--dark select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px var(--v-background-base) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

</style>

<script>

  import services from '@/services.js'
  import { App } from '@capacitor/app';
  import { sync } from 'vuex-pathify'
  import OneSignal from 'onesignal-cordova-plugin';

  export default {
    name: 'App',

    metaInfo () {
      return {
        title: 'Mobees',
        titleTemplate: '%s | Parceiros',
        htmlAttrs: { lang: 'en' },
        meta: [{ charset: 'utf-8' }],
      }
    },

    data: () => ({
      refreshing: false,
      registration: null,
      updateExists: false,
      temp: {},
      modal: null,
      active: true,
      dev: process.env.NODE_ENV != 'production',
    }),

    computed: {
      version: sync('app/version'),
      user: sync('user'),
      toast: sync('app/toast'),
      
      installed () {
        return typeof mobees != 'undefined';
      },
    },

    watch: {
      $route: {
        immediate: true,
        handler (route) {
          this.modal = _.get(route.query, 'view', null);
          
          if (_.has(route.query, 'debug')) {
            this.setStorage('debug', true, 'sessionStorage');
            this.dev = true;
          }else if (!this.dev) {
            this.getStorage('debug', 'sessionStorage').then(value => {
              this.dev = value===true;
            });
          }
        },
      },
      isOnline: {
        immediate: true,
        handler (on) {
          this.toggleToast(
            !on,
            'Sem conexão de internet 😬',
            -1,
            false,
          );
        },
      }
    },

    methods: {
      ...services,

      onStateChange ({ isActive }) {
        this.active = isActive;
      },

      showRefreshUI () {
        // this.registration = e.detail;

        this.toggleToast(
          true,
          'Nova versão do app disponível!',
          0,
          {
            toggle: true,
            text: 'Atualizar',
            action: this.refreshApp
          },
        )
      },
      
      async refreshApp () {
        this.updateExists = false;
        // localStorage.removeItem("vuex");

        // if (this.user.auth.status) this.user = Object.assign(this.user, this.temp);
        // this.temp.updated = true;
        // if (this.user.auth.status) this.temp.user = this.user;
        // localStorage.setItem("temp", JSON.stringify(this.temp));
        this.setStorage('updated', true);

        await this.$workbox.messageSW({ type: "SKIP_WAITING" });
        // this.showUpgradeUI = false

        // if (!this.registration || !this.registration.waiting) { return; }
        // this.registration.waiting.postMessage('skipWaiting');
      },

      adjustUI () {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      },

      OneSignalInit () {
        // Uncomment to set OneSignal device logging to VERBOSE  
        OneSignal.setLogLevel(6, 0);

        // NOTE: Update the setAppId value below with your OneSignal AppId.
        OneSignal.setAppId("162a5a01-8253-46a8-bee7-664c5afd968c");
        OneSignal.setNotificationOpenedHandler(function(jsonData) {
            console.log('### notificationOpenedCallback: ' + JSON.stringify(jsonData));
        });

        // Prompts the user for notification permissions.
        //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
        OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
            console.log("### User accepted notifications: " + accepted);
        });
      }
    },

    mounted () {
      this.$router.onReady(() => {
        const route = this.$route;
        this.getStorage('user').then((user) => {
          console.log(route);
          if (route.name=='file'||route.name=='Manutenção') return;
          
          if ((user===null||user.auth.token==null)&&route.path!='/') {
            this.$router.push('/');
          }else if (!!user&&user.auth.token!=null&&user.status!=null) {
            if (user.status=='ACT') {
              this.$router.push('/main');
            }else{
              this.$router.push('/signup');
            }
          }
        });

      });
      
      this.getStorage('updated').then((updated) => {
        if (updated===true) {
          // setTimeout(() => {
          //   this.toggleToast(
          //     true,
          //     'App atualizado!',
          //     3000,
          //     false
          //   )
          // }, 500);
          this.setStorage('updated', false);
        }
      });
      // const temp = JSON.parse(localStorage.getItem("temp"));
      // if (temp!=null) {
      //   console.log(temp);
      //   this.user = Object.assign(this.user, this.temp.user);
      //   localStorage.removeItem("temp");
      //   if (temp.updated) {
      //     setTimeout(() => {
      //       this.toggleToast(
      //         true,
      //         'App atualizado!',
      //         3000,
      //         false
      //       )
      //     }, 500);
      //   }
        // this.redirect = temp.redirect;
      // }

      if (this.$workbox) {
        this.$workbox.addEventListener("waiting", () => {
          this.showRefreshUI();
        });
      }

      if (this.installed) {
        document.addEventListener("deviceready", this.OneSignalInit, false);
      }
      this.adjustUI();
      window.addEventListener('resize', this.adjustUI);

      this.version = APP_VERSION;
      console.log(this.version);

      App.addListener('appStateChange', this.onStateChange);

      // const version = localStorage.getItem("app_version");
      // if (version==null||version!=APP_VERSION) {
      //   console.log('app version updated', APP_VERSION);
      //   this.setAppVersion(APP_VERSION);
      // }
    }
  }
</script>
