<template>
  <g>
    <path d="M0 9.1237C0 7.93956 0.906562 6.99955 2.0925 6.99955C3.27843 6.99955 4.21987 7.93998 4.21987 9.1237V9.9945C5.40581 8.35776 7.01016 6.82547 9.83489 6.82547C12.4857 6.82547 14.3686 8.11406 15.3453 10.0641C16.8102 8.11406 18.7632 6.82547 21.5531 6.82547C25.5642 6.82547 28.0053 9.3674 28.0053 13.8598V23.6803C28.0053 24.8644 27.0983 25.7696 25.9128 25.7696C24.7269 25.7696 23.7855 24.864 23.7855 23.6803V15.1484C23.7855 12.2231 22.4252 10.6564 20.0537 10.6564C17.752 10.6564 16.1128 12.2583 16.1128 15.2181V23.6799C16.1128 24.864 15.171 25.7692 14.0203 25.7692C12.8344 25.7692 11.8929 24.8636 11.8929 23.6799V15.1136C11.8929 12.2579 10.4978 10.6564 8.16122 10.6564C5.82465 10.6564 4.22029 12.3976 4.22029 15.2181V23.6799C4.22029 24.864 3.27886 25.7692 2.09293 25.7692C0.941859 25.7692 0.000421741 24.8636 0.000421741 23.6799V9.1237H0Z" />
    <path d="M30.375 16.5062V16.4366C30.375 11.1782 34.56 6.82504 40.2103 6.82504C45.8602 6.82504 50.0103 11.1082 50.0103 16.367V16.4366C50.0103 21.6597 45.8253 26.0129 40.1402 26.0129C34.5251 26.0129 30.375 21.7298 30.375 16.5062ZM45.7905 16.5062V16.4366C45.7905 13.1979 43.4535 10.5163 40.1406 10.5163C36.7575 10.5163 34.5953 13.1631 34.5953 16.367V16.4366C34.5953 19.6404 36.9319 22.3216 40.2103 22.3216C43.6282 22.3216 45.7905 19.6753 45.7905 16.5062Z" />
    <path d="M52.5554 2.08934C52.5554 0.905196 53.462 0 54.6479 0C55.8339 0 56.7753 0.90562 56.7753 2.08934V10.2726C58.1356 8.35777 60.0537 6.82505 63.0532 6.82505C67.3777 6.82505 71.5976 10.2378 71.5976 16.367V16.4366C71.5976 22.5305 67.4126 25.9781 63.0532 25.9781C59.984 25.9781 58.0658 24.4458 56.7753 22.7394V23.6799C56.7753 24.8292 55.8339 25.7692 54.6479 25.7692C53.4969 25.7692 52.5554 24.8288 52.5554 23.6799V2.08934ZM67.3079 16.4366V16.367C67.3079 12.815 64.9012 10.4815 62.0416 10.4815C59.1816 10.4815 56.6707 12.8498 56.6707 16.367V16.4366C56.6707 19.9538 59.182 22.3216 62.0416 22.3216C64.9361 22.3216 67.3079 20.0582 67.3079 16.4366Z" />
    <path d="M82.652 26.0129C77.3159 26.0129 73.2002 22.1476 73.2002 16.4714V16.4018C73.2002 11.1434 76.9323 6.82504 82.1987 6.82504C88.0578 6.82504 90.9522 11.6304 90.9522 15.8791C90.9522 17.0633 90.0457 17.8988 88.9648 17.8988H77.4205C77.8742 20.8938 80.0016 22.5649 82.7217 22.5649C84.5004 22.5649 85.8955 21.9382 87.081 20.963C87.3948 20.7193 87.6738 20.58 88.1624 20.58C89.1042 20.58 89.8364 21.3111 89.8364 22.2864C89.8364 22.809 89.5924 23.2616 89.2786 23.575C87.604 25.0729 85.512 26.0129 82.652 26.0129ZM86.8374 15.1136C86.558 12.3972 84.9541 10.273 82.1638 10.273C79.5831 10.273 77.7696 12.2579 77.386 15.1136H86.8374Z" />
    <path d="M101.972 26.0129C96.6357 26.0129 92.52 22.1476 92.52 16.4714V16.4018C92.52 11.1434 96.2522 6.82504 101.518 6.82504C107.378 6.82504 110.272 11.6304 110.272 15.8791C110.272 17.0633 109.365 17.8988 108.285 17.8988H96.7403C97.194 20.8938 99.3214 22.5649 102.042 22.5649C103.82 22.5649 105.215 21.9382 106.401 20.963C106.715 20.7193 106.994 20.58 107.482 20.58C108.424 20.58 109.156 21.3111 109.156 22.2864C109.156 22.809 108.912 23.2616 108.598 23.575C106.924 25.0729 104.831 26.0129 101.972 26.0129ZM106.157 15.1136C105.878 12.3972 104.274 10.273 101.484 10.273C98.903 10.273 97.0898 12.2579 96.7059 15.1136H106.157Z" />
    <path d="M112.154 23.7495C111.701 23.5058 111.352 22.9836 111.352 22.2868C111.352 21.3464 112.085 20.5805 113.061 20.5805C113.41 20.5805 113.723 20.6849 113.968 20.8242C115.746 22.0083 117.595 22.6002 119.269 22.6002C121.082 22.6002 122.129 21.8342 122.129 20.6157V20.5457C122.129 19.1178 120.176 18.6308 118.014 17.9689C115.293 17.2025 112.259 16.0885 112.259 12.5713V12.5016C112.259 9.01928 115.154 6.89513 118.816 6.89513C120.769 6.89513 122.827 7.45259 124.605 8.3926C125.198 8.70594 125.617 9.26298 125.617 9.99453C125.617 10.9698 124.849 11.7009 123.873 11.7009C123.524 11.7009 123.315 11.6308 123.036 11.4916C121.536 10.7256 120.002 10.2378 118.711 10.2378C117.072 10.2378 116.13 11.0042 116.13 12.0486V12.1183C116.13 13.4765 118.118 14.0335 120.28 14.7298C122.966 15.5658 126 16.7848 126 20.0931V20.1627C126 24.0285 123 25.9433 119.164 25.9433C116.828 25.9433 114.282 25.2122 112.154 23.7495Z" />
  </g>
</template>
<script>
  export default {
    mounted () {
      this.$emit('load');
    }
  }
</script>