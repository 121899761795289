// Imports
import {
  make,
} from 'vuex-pathify'

const state = {
  footer: 'Caso ainda esteja com dúvidas entre em contato com nosso atendimento via Whatsapp: <br> <a href="https://wa.me/5521990669268" target="_blank">21 99066-9268</a>',
  
  sections: {

    signup: {
      id: '007',
      title: 'Processo Seletivo',
      subtitle: 'Processo Seletivo',
      toggle: false,
    },

    access: {
      id: '001',
      title: 'Acesso',
      subtitle: 'Acesso',
      toggle: false,
    },

    goal: {
      id: '002',
      toggle: false,
      title: 'Horas no ar',
      subtitle: 'Horas no ar',
    },

    manual: {
      id: '007',
      toggle: false,
      title: 'Manual do Equipamento',
      subtitle: 'Manual',
    },

    referrals: {
      id: '003',
      toggle: false,
      title: 'Indicações',
      subtitle: 'Indicações',
    },

    payments: {
      id: '004',
      toggle: false,
      title: 'Extrato',
      subtitle: 'Extrato',
    },

    bonus: {
      id: '005',
      toggle: false,
      title: 'Prêmios',
      subtitle: 'Prêmios',
    },

    profile: {
      id: '006',
      toggle: false,
      title: 'Perfil',
      subtitle: 'Perfil',
    },

  }
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
